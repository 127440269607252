import { setBadges } from '../sidebar/actions';
import { fetchCountersSalary, setUpdatedDataNew } from '../salary/actions';
import { WS_CONNECT, WS_DISCONNECT } from './types';
import { debounce } from '../../utils/helpers';
import axios from '../../services/axios';

let socket = null;

const delayForGetCounters = debounce((dispatch) => {
  dispatch(fetchCountersSalary());
}, 2000);
const middleware = (store) => (next) => (action) => {
  const handleReviewChange = (data) => {
    const { count_unconfirmed_worklogs: countUnconfirmedWorklogs } = JSON.parse(data);
    store.dispatch(setBadges({ mainReviewCount: countUnconfirmedWorklogs }));
  };

  const handleVacationChange = (data) => {
    const { count_unconfirmed_vacations: countUnconfirmedVacations } = JSON.parse(data);
    store.dispatch(setBadges({ vacationCount: countUnconfirmedVacations }));
  };

  const handleRecommendedInvoicesChange = (data) => {
    const { count_recommended_invoices: countRecommendedInvoices } = JSON.parse(data);
    store.dispatch(setBadges({ invoiceRecommendedCount: countRecommendedInvoices }));
  };

  const handleSalariesChange = (data) => {
    const { salaries } = JSON.parse(data);
    store.dispatch(setUpdatedDataNew(salaries, 'socketSalaries', 'just'));
    delayForGetCounters(store.dispatch);
  };

  const handleSalariesDelete = () => {
    store.dispatch(fetchCountersSalary());
  };

  const handleMessage = async (event, channel) => {
    const message = JSON.parse(event.data);

    switch (message.event) {
      case 'pusher:connection_established': {
        const messageData = JSON.parse(message.data);

        const { auth } = await fetch(process.env.NEXT_PUBLIC_WS_BROADCASTING, {
          method: 'post',
          body: new URLSearchParams({
            socket_id: messageData.socket_id,
            channel_name: channel,
          }),
          headers: {
            Authorization: axios.defaults.headers.common['Authorization'],
            'Content-Type': 'application/x-www-form-urlencoded'
          },
        }).then((res) => res.json());

        if (auth) {
          socket.send(JSON.stringify({
            event: 'pusher:subscribe',
            data: { auth, channel },
          }));
        }

        break;
      }
      case 'review.change_count':
        handleReviewChange(message.data);
        break;
      case 'vacation.change_count':
        handleVacationChange(message.data);
        break;
      case 'recommended_invoice.change_count':
        handleRecommendedInvoicesChange(message.data);
        break;
      case 'salaries.changed':
        handleSalariesChange(message.data);
        break;
      case 'salaries.deleted':
        handleSalariesDelete();
        break;
      default:
        break;
    }
  };

  const connectWebSocket = (profileId) => {
    const wsProtocol = JSON.parse(process.env.NEXT_PUBLIC_WS_TLS) ? 'wss' : 'ws';
    const wsHost = process.env.NEXT_PUBLIC_WS_HOST;
    const wsPath = `/app/${process.env.NEXT_PUBLIC_WS_APP_KEY}`;
    const wsPort = process.env.NEXT_PUBLIC_WS_PORT;
    const wsUrl = `${wsProtocol}://${wsHost}:${wsPort}${wsPath}`;
    let pingInterval;

    socket = new WebSocket(wsUrl);
    socket.onmessage = (event) => handleMessage(event, `private-users.${profileId}`);
    socket.onopen = () => {
      pingInterval = setInterval(() => {
        if (socket.readyState === WebSocket.OPEN) {
          socket.send(JSON.stringify({ event: 'pusher:ping', 'data': {} }));
        }
      }, 30000);
    };
    socket.onclose = () => {
      clearInterval(pingInterval);
      setTimeout(() => connectWebSocket(profileId), 5000);
    };
  };

  switch (action.type) {
    case WS_CONNECT:
      if (socket === null) {
        const { profileId } = action;
        connectWebSocket(profileId);
      }

      break;
    case WS_DISCONNECT:
      if (socket !== null) {
        socket.close();
        socket = null;
      }

      break;
    default:
      break;
  }

  return next(action);
};

export default middleware;
